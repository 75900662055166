/**
 *	Serwis na potrzeby dashboardu
 *  @since 13.12.2019
 */

// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

// Models
// import { DeviceModel } from '../models/device.model';

@Injectable()
export class DashboardService {
  url = environment.url;

  /**
   * Service Constructor
   *
   * @param http: HttpClient
   */
  constructor(private http: HttpClient) {}

  /**
   * Dane na potrzeby bloczków z ogólnymi statystykami
   */
  getStats() {
    return this.http.get<{ data }>(`${this.url}/dashboard/stats`);
  }

  /**
   * Dane na potrzeby wykresów
   */
  getCharts(period, begin, end) {
    return this.http.get<{ data }>(`${this.url}/dashboard/charts?&period=${period}&begin=${begin}&end=${end}`);
  }
}
