import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ToastService {
  constructor(private matSnackBar: MatSnackBar) {}
  /**
   * Wyswietlanie toastow - na potrzeby importu filtrów
   */
  displayToast(message) {
    this.matSnackBar.open(message, 'Zamknij', {
      duration: 3000
    });
  }
}
