// Angular
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../../../core/auth/_services/auth.service';

/**
 * Sample context menu dropdown
 */
@Component({
  selector: 'kt-column-visibility-menu',
  templateUrl: './column-visibility-menu.component.html',
  styleUrls: ['./column-visibility-menu.component.scss']
})
export class ColumnVisibilityMenuComponent implements OnInit {
  constructor(private authService: AuthService) {}
  columnOptions: any = [];
  @Output()
  columnVisibilityChange = new EventEmitter<any>();

  @Input()
  set visibleColumns(visibleColumns: any) {
    if (visibleColumns) {
      this.columnOptions = visibleColumns;
    }
  }

  // @Input() visibleColumns: any = [];
  ngOnInit() {
    this.getRole();
  }

  /**
   * Funkcja zmieniająca widoczność danej kolumny, jako zdarzenie przesyla czy checkbox zostal zaznaczony
   */
  changeStatus(pos, i) {
    if (pos) {
      // kontrola widoczności
      this.columnOptions[i].visibility = !this.columnOptions[i].visibility;
      const posVisibility = this.columnOptions.map(row => row.visibility);
      // zapisanie opcji true/false w localStorage
      localStorage.setItem('diagnosis_v', JSON.stringify(posVisibility));
      // przesłanie zdarzenia
      this.columnVisibilityChange.emit(this.columnOptions);
    }
  }

  /**
   * Funkcja sprawdzająca czy uzytkownik posiada okresloną rolę, na bazie ktorej udziela dostepu do zastrzezonej tresci
   */

  getRole() {
    this.authService.getRole().subscribe(roles => {
      if (!roles.includes('operator')) {
        // Wyświetl tylko te opcje wyboru checkbox, które nie mają parametru operator
        this.columnOptions = this.columnOptions.filter(
          cd => cd.role !== 'operator'
        );
      }
    });
  }
}
