import * as moment from 'moment';

export function labelClass(lastReport) {
  const now = moment(new Date()); // dzisiejsza data
  const end = moment(lastReport).format('YYYY-MM-DD HH:mm:ss');
  const duration = moment.duration(now.diff(end));
  const minutes = duration.asMinutes();
  if (minutes > 190) {
    return {
      class: 'bg-danger'
    };
  } else if (minutes > 130) {
    return {
      class: 'bg-warning'
    };
  } else {
    return {
      class: 'bg-success'
    };
  }
}
