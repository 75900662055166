// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('app/views/pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'instrukcja_demontazu.pdf',
    redirectTo: '../assets/media/instructions/instrukcja_demontazu.pdf',
    pathMatch: 'full',
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('app/views/pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'pos',
        loadChildren: () =>
          import('app/views/pages/pos/list/pos.module').then(
            (m) => m.PosModule
          ),
      },
      {
        path: 'pos/details/:idaps',
        loadChildren: () =>
          import('app/views/pages/pos/details/details.module').then(
            (m) => m.PosDetailsModule
          ),
      },
      {
        path: 'pos/stats/:idaps',
        loadChildren: () =>
          import('app/views/pages/pos/stats/stats.module').then(
            (m) => m.PosStatsModule
          ),
      },
      {
        path: 'pos/stats/minute/:idaps',
        loadChildren: () =>
          import('app/views/pages/pos/stats/minute/minute.module').then(
            (m) => m.PosMinuteStatsModule
          ),
      },
      {
        path: 'diagnostics',
        loadChildren: () =>
          import(
            'app/views/pages/diagnostics/diagnosticsDashboard/diagnostics.module'
          ).then((m) => m.DiagnosticsModule),
      },
      {
        path: 'sensors',
        loadChildren: () =>
          import(
            'app/views/pages/sensors/sensorsDashboard/sensors.module'
          ).then((m) => m.SensorsModule),
      },
      {
        path: 'kr',
        loadChildren: () =>
          import('app/views/pages/kr/list/kr.module').then(
            (m) => m.KrListModule
          ),
      },
      {
        path: 'rks',
        loadChildren: () =>
          import('app/views/pages/rks/list/rks.module').then(
            (m) => m.RksListModule
          ),
      },
      {
        path: 'alarms',
        loadChildren: () =>
          import('app/views/pages/alarms/list/alarms.module').then(
            (m) => m.AlarmsListModule
          ),
      },
      {
        path: 'instructions',
        loadChildren: () =>
          import('app/views/pages/instructions/instructions.module').then(
            (m) => m.InstructionsModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('app/views/pages/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'error/403',
        component: ErrorPageComponent,
        data: {
          type: 'error-v6',
          code: 403,
          title: '403... Access forbidden',
          desc: "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
        },
      },
      { path: 'error/:type', component: ErrorPageComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },

  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
