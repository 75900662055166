// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule
} from '@angular/material';
// NgBootstrap
import {
  NgbDropdownModule,
  NgbTabsetModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Core module
import { CoreModule } from '../../core/core.module';
// Modal
import { UpdatePosComponent } from '../pages/pos/updatePos/pos.component';

// Layout partials
import {
  ColumnVisibilityMenuComponent,
  QuickPanelComponent,
  ScrollTopComponent,
  SearchResultComponent,
  SplashScreenComponent,
  Subheader1Component,
  SubheaderSearchComponent,
  UserProfile3Component
} from './layout';
// General
import { PortletModule } from './content/general/portlet/portlet.module';
// Errpr
import { ErrorComponent } from './content/general/error/error.component';
// Extra module
import { WidgetModule } from './content/widgets/widget.module';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
  entryComponents: [UpdatePosComponent],
  declarations: [
    ScrollTopComponent,
    // topbar components
    QuickPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    Subheader1Component,
    SubheaderSearchComponent,
    UserProfile3Component,
    ErrorComponent,
    ColumnVisibilityMenuComponent,
    UpdatePosComponent
  ],
  exports: [
    WidgetModule,
    PortletModule,

    ScrollTopComponent,
    ColumnVisibilityMenuComponent,
    // topbar components
    QuickPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    Subheader1Component,
    SubheaderSearchComponent,
    UserProfile3Component,
    ErrorComponent
  ],
  providers: [UpdatePosComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    InlineSVGModule,
    CoreModule,
    PortletModule,
    WidgetModule,

    // angular material modules
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,

    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule
  ]
})
export class PartialsModule {}
