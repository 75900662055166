// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../../core/auth';

import { AuthService } from '../../../../../core/auth/_services/auth.service';

@Component({
  selector: 'kt-user-profile3',
  templateUrl: './user-profile3.component.html',
})
export class UserProfile3Component implements OnInit {
  // Public properties
  user$: Observable<User>;

  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

  restrictedAreaToOwner = false;

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   */
  constructor(
    private store: Store<AppState>,
    private authService: AuthService
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.user$ = this.store.pipe(select(currentUser));
    this.getRole();
  }
  /**
   * Funkcja sprawdzająca rolę uzytkownika
   */
  getRole() {
    this.authService.getRole().subscribe((roles) => {
      // Jezeli rola uzytkownika to owner
      if (roles.includes('owner')) {
        this.restrictedAreaToOwner = true;
      }
    });
  }

  /**
   * Log out
   */
  logout() {
    this.store.dispatch(new Logout());
  }
}
