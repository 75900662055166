import * as moment from 'moment';
import * as XLSX from 'xlsx';
// RXJS
import { startWith, tap } from 'rxjs/operators';
// Angular
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// Serwisy
import { PosSensorsService } from '../../../../services/pos-sensors.service';
import { TableService } from '../../../../services/table.service';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { ToastService } from '../../../../services/toast.service';
// Okno modalne
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// Helper
import { labelClass } from '../../../../helpers/labelClass';
// Komponenty
import { UpdatePosComponent } from '../updatePos/pos.component';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'kt-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.scss'],
})
export class PosComponent implements OnInit {
  @ViewChild(MatPaginator, {
    static: true,
  })
  paginator: MatPaginator;
  @ViewChild(MatSort, {
    static: true,
  })
  // sortowanie kolumn
  sort: MatSort;
  // na potrzeby przekazania danych z serwera do tabeli
  // dataSource = new MatTableDataSource();
  // dataSource: MatTableDataSource<any>;
  dataSource: any;

  // formGroup w celu zapamiętywania wartości pobranych z local storage do filtrów w tabeli
  posFormGroup: FormGroup;

  // DLA IMPORTU PLIKU EXCEL //

  // nazwa arkusza Excel
  sheetName: any;
  // tablica filtrów na potrzeby importowania pliku excel
  sfidFilters: any = [];
  // Rodzaj widoku filtra - inny dla osób, które wybrały plik excel
  tableView: any;
  // Strefy zastrzezone dla operatora
  restrictedToOperatorAccess = false;
  // Strefy zastrzezone dla admina
  restrictedToAdminAccess = false;

  // ________ //

  // DLA ZAZNACZANIA WIERSZY W TABELI //

  // wybrane wiersze
  selection: any;
  initialSelection: any = [];

  // ________ //

  // Suma wszystkich sensorów
  sumSensorsCount: any;
  // Średnie wartości kolumn
  avgReportingDaysPercentage: any;
  avgReportsCountPercentage: any;

  // kolumny, które są wyswietlanie w tabeli - usunięcie ukryje kolumnę
  columnsToDisplay = [
    'select',
    'index',
    'sfid',
    //'network',
    'address',
    'email',
    'phone',
    //'gallery',
    'status',
    //'posStatus',
    'posStatusDesc',
    // 'extendedStatus',
    'protocols',
    'documentation',
    'sensorsCount',
    'reportingDaysPercentage',
    'reportsCountPercentage',
    'diffSum',
    'alarmThreshold',
    // 'undefSum',
    // 'diffVerifiedSum',
    'actions',
  ];
  // obiekt do którego przypisywane są wartości z local storage
  pos: any = {};

  posArray = [
    {
      col: 'select',
      name: 'Checkbox',
      visibility: true,
      actions: true,
      role: ['operator'],
    },
    { col: 'index', name: 'Index', visibility: true, actions: true },
    { col: 'sfid', name: 'Dworzec', visibility: true },
    { col: 'address', name: 'Adres', visibility: true },
    { col: 'email', name: 'Email manager', visibility: true },
    { col: 'phone', name: 'Telefon manager', visibility: true },
    { col: 'status', name: 'Status', visibility: true },
    {
      col: 'posStatusDesc',
      name: 'Uwaga',
      visibility: true,
      role: ['operator', 'admin'],
    },
    {
      col: 'protocols',
      name: 'Protokoły',
      visibility: true,
      role: ['operator'],
    },
    {
      col: 'documentation',
      name: 'Dokumentacja',
      visibility: true,
      role: ['operator'],
    },
    { col: 'sensorsCount', name: 'Liczniki', visibility: true },
    { col: 'alarmThreshold', name: 'Próg alarmowy', visibility: true },
    // {
    //   col: 'reportingDaysPercentage',
    //   name: 'Dni %',
    //   visibility: true,
    //   role: ['operator'],
    // },
    // {
    //   col: 'reportsCountPercentage',
    //   name: 'Raporty %',
    //   visibility: true,
    //   role: ['operator'],
    // },
    // {
    //   col: 'diffSum',
    //   name: 'Różnica',
    //   visibility: true,
    //   role: ['operator'],
    // },
    { col: 'actions', name: 'Akcje', visibility: true, actions: true },
  ];

  selectOptions: any;
  // pola, które mogą zostać wpisane z wielkich liter
  allowedUppercaseFilters = ['address', 'email'];

  // obiekt przechowujący listę punktów pos
  posList: any = [];

  // obiekt przechowujący listę statusów
  posStatusArray: any = [];

  // zachowywanie szczegolow posa w local storage
  retrievePosDetails: any;

  constructor(
    private cd: ChangeDetectorRef,
    private tableService: TableService,
    private posSensorsService: PosSensorsService,
    private modalService: NgbModal,
    private authService: AuthService,
    private toastService: ToastService
  ) {}

  /**
   * Init
   */

  ngOnInit() {
    // pobranie roli uzytkownika
    this.getRole();
    // pobranie listy posów
    this.getPosList();
    // pobranie listy statusów
    this.posStatusList();
    // console.log(this.selectOptions);

    for (var i = 0; i < this.posArray.length; i++) {
      if (!this.posArray[i].actions) {
        this.pos[this.posArray[i].col] = '';
      }
    }
    this.controlColumnVisibility(this.posArray);

    this.posFormGroup = new FormGroup({
      sfidFilter: new FormControl(''),
      networkFilter: new FormControl(''),
      addressFilter: new FormControl(''),
      emailFilter: new FormControl(''),
      phoneFilter: new FormControl(''),
      galleryFilter: new FormControl(''),
      statusFilter: new FormControl(''),
      posStatusFilter: new FormControl(''),
      posStatusDescFilter: new FormControl(''),
      extendedStatusFilter: new FormControl(''),
      protocolsFilter: new FormControl(''),
      documentationFilter: new FormControl(''),
      sensorsCountFilter: new FormControl(''),
      reportingDaysPercentageFilter: new FormControl(''),
      reportsCountPercentageFilter: new FormControl(''),
      diffSumFilter: new FormControl(''),
      undefSumFilter: new FormControl(''),
      diffVerifiedSumFilter: new FormControl(''),
      alarmFilter: new FormControl(''),
    });

    // na potrzeby importu pliku
    this.tableView = 'filters';
    // zrodlo danych dla tabeli i ustawienia
    this.dataSource = new MatTableDataSource();
    // inicjacja filtracji kolumn
    this.dataSource.filterPredicate = this.createFilter();
    // uruchomienie paginacji i sortowania
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // na potrzeby wyboru wierszy w tabeli
    const allowMultiSelect = true;
    this.selection = new SelectionModel(
      allowMultiSelect,
      this.initialSelection
    );

    // jezeli nie ma w storage POS, to utwórz z pustymi wartościami
    if (!localStorage.getItem('pos')) {
      localStorage.setItem('pos', JSON.stringify(this.pos));
    }
    // przypisz wartość ze storage do zmiennej retrievePosDetails
    this.retrievePosDetails = JSON.parse(localStorage.getItem('pos'));

    this.checkIfSfidMultipleArrayInStorage();
  }

  // Funkcja sprawdza czy jest więcej niz jeden sfid w storage - jezeli tak to zmien tryb wyswietlania na dropdown

  checkIfSfidMultipleArrayInStorage() {
    // jezeli w local storage jest tablica sfid
    if (this.retrievePosDetails.sfid) {
      if (Array.isArray(this.retrievePosDetails.sfid)) {
        if (this.retrievePosDetails.sfid.length > 1) {
          // utworz inny widok pola wyboru w tablicy - select multiple
          this.tableView = 'excel';
          // przypisanie wartosci z local storage do filtra
          this.sfidFilters = this.retrievePosDetails.sfid;
          // utworzenie filtra
          this.dataSource.filterPredicate = this.createFilter();
        }
      }
    }
  }

  /**
   * Funkcja ukrywająca kolumny na potrzeby excela
   */
  hiddenExcelColumns() {
    const hidden = [];
    hidden.push(this.controlColumnVisibility(this.posArray).indexOf('select'));
    hidden.push(this.controlColumnVisibility(this.posArray).indexOf('actions'));

    return hidden;
  }

  /**
   * Funkcja odbierająca które kolumny powinny być widoczne
   */

  controlColumnVisibility(posArray): string[] {
    // DLA OPERATORA
    if (this.restrictedToOperatorAccess == true) {
      return posArray
        .filter(
          (cd) =>
            (cd.visibility && cd.role && cd.role.includes('operator')) ||
            (cd.visibility && !cd.role)
        )
        .map((cd) => cd.col);
    }
    // DLA ADMINA
    else if (this.restrictedToAdminAccess == true) {
      return posArray
        .filter(
          (cd) =>
            (cd.visibility && cd.role && cd.role.includes('admin')) ||
            (cd.visibility && !cd.role)
        )
        .map((cd) => cd.col);
    }
    // DLA POZOSTAŁYCH RÓL
    else {
      return posArray
        .filter((cd) => !cd.role && cd.visibility)
        .map((cd) => cd.col);
    }
  }

  /**
   * Dodawanie właściwości do obiektu POS
   */

  addPosDetailsToLocalStorage() {
    localStorage.setItem('pos', JSON.stringify(this.pos));
  }

  /**
   * Funkcja odpowiadająca za pobranie roli z tokena
   */

  getRole() {
    this.authService.getRole().subscribe((roles) => {
      if (roles.includes('operator')) {
        this.restrictedToOperatorAccess = true;
      }
      if (roles.includes('admin')) {
        this.restrictedToAdminAccess = true;
      }
    });
  }

  /**
   * Na potrzeby zaznaczania wierszy w tabeli - Sprawdzanie czy zostały zaznaczone wszystkie wiersze
   */

  isAllSelected() {
    return this.tableService.isAllSelected(this.selection, this.dataSource);
  }

  /**
   * Na potrzeby zaznaczania wierszy w tabeli - Funkcjonalność głównego włącznika
   */

  masterToggle() {
    return this.tableService.masterToggle(this.selection, this.dataSource);
  }

  /**
   * Funkcja odpowiadająca za zresetowanie filtrów
   */

  resetFilter() {
    this.dataSource.filter = '';
    this.posFormGroup.patchValue({
      sfidFilter: [''],
      networkFilter: '',
      addressFilter: '',
      emailFilter: '',
      phoneFilter: '',
      galleryFilter: '',
      statusFilter: [''],
      posStatusFilter: '',
      posStatusDescFilter: '',
      extendedStatusFilter: '',
      protocolsFilter: '',
      documentationFilter: '',
      sensorsCountFilter: '',
      reportingDaysPercentageFilter: '',
      reportsCountPercentageFilter: '',
      diffSumFilter: '',
      alarmFilter: '',
    });
    // przypisanie wartosci z local storage do filtra
    this.sfidFilters = [];
    // Powrót do domyślnego filtrowania
    this.tableView = 'filters';
    this.dataSource.filterPredicate = this.createFilter();
  }

  /**
   * Funkcja odpowiadająca za ustanowienie filtrów
   */

  // setValue polega na ustawieniu wartości w polu szukania
  // startWith uruchamia inicjalnie filtr z podaną wartością
  setFilters(allowedUppercaseFilters) {
    // pola, które mogą zostać wpisane z wielkich liter
    for (const [index, [key, value]] of Object.entries(
      Object.entries(this.pos)
    )) {
      this.posFormGroup
        .get(Object.keys(this.posFormGroup.controls)[index])
        .setValue(this.retrievePosDetails[key]);
      this.posFormGroup
        .get(Object.keys(this.posFormGroup.controls)[index])
        .valueChanges.pipe(startWith(this.retrievePosDetails[key]))
        .subscribe((value) => {
          if (allowedUppercaseFilters.includes(key)) {
            this.pos[key] = value.toLowerCase();
          } else {
            this.pos[key] = value;
          }
          // this.sumSensorsCount
          this.dataSource.filter = JSON.stringify(this.pos);
          this.sumSensorsCount = this.dataSource.filteredData.reduce(
            (sCSum, v) => (sCSum += parseInt(v.sensorsCount)),
            0
          );
          //https://stackoverflow.com/questions/6736476/how-to-turn-nan-from-parseint-into-0-for-an-empty-string
          this.avgReportingDaysPercentage =
            this.dataSource.filteredData.reduce(
              (aRSum, v) => (aRSum += parseInt(v.reportingDaysPercentage) || 0),
              0
            ) / this.dataSource.filteredData.length;
          this.avgReportsCountPercentage =
            this.dataSource.filteredData.reduce(
              (aRSum, v) => (aRSum += parseInt(v.reportsCountPercentage) || 0),
              0
            ) / this.dataSource.filteredData.length;
          this.addPosDetailsToLocalStorage();
        });
    }
  }

  /**
   * Mozliwosc importowania pliku Excel na potrzeby konta Operator
   */

  applyFiltersByExcel(ev) {
    this.tableService.importExcelFile(
      ev,
      this.posFormGroup,
      this.pos,
      this.sfidFilters
    );
    this.tableView = 'excel';
    this.dataSource.filterPredicate = this.createFilter();
    ev.target.value = '';
    this.selection.clear();
  }

  /**
   * Funkcja umozliwia zaimportowanie pliku Excel w celu aktualizacji wartosci posów
   */

  importExcelToUpdatePos(ev) {
    this.tableService.importExcelToUpdatePos(ev);
    // ev.target.value = '';
  }

  /**
   * Funkcja dostosowująca widok labela prezentującego ostatni raport w zaleznosci od roznicy czasu
   * @param lastReport
   */

  labelColor(lastReport) {
    return labelClass(lastReport);
  }

  /**
   * Funkcja zwracająca listę statusów
   */

  posStatusList() {
    this.posSensorsService.posStatusList().subscribe((res) => {
      this.posStatusArray = res;
    });
  }

  /**
   * Funkcja aktualizująca filtry w tabeli
   */

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      // console.log(searchTerms);
      try {
        let found = false;
        for (var property in searchTerms) {
          if (
            data[property]
              .toLowerCase()
              .toString()
              .indexOf(searchTerms[property]) !== -1
          ) {
            found = true;
          } else if (!searchTerms[property].includes(data[property])) {
            return false;
          }
        }
        return found;
      } catch (err) {}
    };
    return filterFunction;
  }

  /**
   * Pobieranie listy POS
   */

  getPosList() {
    this.posSensorsService
      .getPosList()
      .pipe(
        tap({
          complete: () => this.setFilters(this.allowedUppercaseFilters),
        })
      )
      .subscribe((res) => {
        this.posList = res.data;
        this.dataSource.data = this.posList;
        // jezeli jakakolwiek wartosc jest null lub ''
        this.posList.forEach((value) => {
          Object.keys(value).forEach((key) => {
            if (
              value[key] === '' ||
              value[key] === null ||
              value[key] === '-'
            ) {
              value[key] = '—';
            }
          });
        });
        this.cd.detectChanges();
      });
  }

  /**
   * Masowa edycja POSow na podstawie pola sfid
   */

  updateAllPos() {
    if (this.selection.selected.length == 0) {
      this.toastService.displayToast('Brak zaznaczonych wierszy w tabeli');
    } else {
      // Wyodrębnienie sfid z wybranego wiersza i utworzenie tablicy
      var idApsArray = [];
      this.selection.selected.map((row) => {
        idApsArray.push(row.idaps);
      });
      const modalRef = this.modalService.open(UpdatePosComponent, {
        size: 'lg',
      });
      // przekazywanie tablicy sfid
      modalRef.componentInstance.idApsArray = idApsArray;
      // pobranie listy w przypadku pomyślnej edycji
      modalRef.result.then((result) => {
        if (result) {
          this.ngOnInit();
        }
      });
    }
  }

  /**
   * Edycja szczegółów POS w oknie modalnym
   */

  updatePosDetails(idaps, status, single) {
    const modalRef = this.modalService.open(UpdatePosComponent, {
      size: 'lg',
    });
    // przekazywanie adresu i id urządzenia
    modalRef.componentInstance.idApsArray = [idaps];
    modalRef.componentInstance.status = status;
    modalRef.componentInstance.single = single;
    // pobranie listy w przypadku pomyślnej edycji
    modalRef.result.then((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }
}
