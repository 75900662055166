import { Directive, ElementRef } from '@angular/core';
@Directive({
  selector: '[ktShowHidePassword]'
})
export class ShowHidePasswordDirective {
  private shown = false;

  constructor(private el: ElementRef) {
    this.setup();
  }
  setup() {
    const parent = this.el.nativeElement;
    const span = document.createElement('span');
    span.className = 'base';
    span.innerHTML = `<i class="material-icons">
    visibility
    </i>`;
    span.addEventListener('click', event => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }

  toggle(span: HTMLElement) {
    this.shown = !this.shown;
    if (this.shown) {
      this.el.nativeElement.previousSibling.setAttribute('type', 'text');
      span.innerHTML = '<i class="material-icons">visibility_off</i>';
    } else {
      this.el.nativeElement.previousSibling.setAttribute('type', 'password');
      span.innerHTML = '<i class="material-icons">visibility</i>';
    }
  }
}
